import React, { useState } from "react";
import Icon from "../../images/logo.png";
import { Link } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import profileImage from "../../images/profile.png";

const Navbar = () => {
  const [open, setOpen] = useState(false);

  return (
    <div className="max-w-screen overflow-x-hidden font-poppins">
      <nav className="bg-darkblue fixed px-2  sm:h-auto sm:w-full w-screen z-20 left-0 right-0 top-0 border-b border-gray-400">
        {/* dektop nav */}
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto sm:p-4 pb-2">
          <Link to="/" className="flex items-center">
            <img
              src={Icon}
              alt="icon"
              className="hidden sm:block sm:w-[60px] sm:-h-[60px] "
            />
          </Link>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
