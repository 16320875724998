import React from "react";
import { Link } from "react-router-dom";
import heroImage from "../../images/8.png";
import Stars from "../../images/stars.png";
import { motion } from "framer-motion";
import cm from "../../images/cm.jpg";

const Herosection = () => {
  return (
    <div className="font-poppins overflow-x-hidden">
      {/* component from tailgrids */}

      <div className="relative  pt-[120px] pb-[110px] lg:pt-[150px] bg-[#e0e0e0]">
        <div className="container mx-auto px-10">
          <div className="-mx-4 flex flex-wrap">
            <div className="hidden px-4 lg:block lg:w-1/12"></div>
            <div className="w-full px-4 lg:w-6/12">
              <div className="lg:ml-auto lg:text-right">
                <motion.div
                  variants={{
                    visible: { opacity: 1, x: 0, filter: "blur(0px)" },
                    hidden: { opacity: 0, x: 75, filter: "blur(5px)" },
                  }}
                  initial="hidden"
                  animate="visible"
                  transition={{ duration: 0.5, delay: 0.25 }}
                  className="relative z-10 inline-block pt-11 lg:pt-0 mt-5 bottom-14"
                >
                  <img src={cm} className="h-96" alt="Coming Soon" />
                </motion.div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* component from tailgrids end */}
    </div>
  );
};

export default Herosection;
